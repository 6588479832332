// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yeh8JH1u8", "o8Q3eosea"];

const serializationHash = "framer-gcKBV"

const variantClassNames = {o8Q3eosea: "framer-v-vdxkmg", yeh8JH1u8: "framer-v-wdwfda"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1, ease: [0.25, 1, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Arrow Active": "o8Q3eosea", Arrow: "yeh8JH1u8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yeh8JH1u8"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yeh8JH1u8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wdwfda", className, classNames)} data-framer-name={"Arrow"} layoutDependency={layoutDependency} layoutId={"yeh8JH1u8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({o8Q3eosea: {"data-framer-name": "Arrow Active"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ypirxz"} layoutDependency={layoutDependency} layoutId={"A0saxELlL"}><SVG className={"framer-itf8x2"} data-framer-name={"Arrow"} fill={"rgba(0,0,0,1)"} intrinsicHeight={14} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"d0wy6AR0e"} svg={"<svg width=\"20\" height=\"14\" viewBox=\"0 0 20 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M9.98714 3.70947H11.632L14.829 6.90641L11.632 10.1431H9.98714L11.3402 8.77682L12.7861 7.42376L10.7831 7.49009H4.82898V6.32274H10.7831L12.7729 6.38906L11.3004 5.02274L9.98714 3.70947Z\" fill=\"#372B29\"/>\n</svg>\n"} withExternalLayout/><SVG className={"framer-1jedx3e"} data-framer-name={"Arrow"} fill={"rgba(0,0,0,1)"} intrinsicHeight={14} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"ozuRzVWTG"} svg={"<svg width=\"20\" height=\"14\" viewBox=\"0 0 20 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M9.98714 3.70947H11.632L14.829 6.90641L11.632 10.1431H9.98714L11.3402 8.77682L12.7861 7.42376L10.7831 7.49009H4.82898V6.32274H10.7831L12.7729 6.38906L11.3004 5.02274L9.98714 3.70947Z\" fill=\"#372B29\"/>\n</svg>\n"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gcKBV.framer-y8rlcs, .framer-gcKBV .framer-y8rlcs { display: block; }", ".framer-gcKBV.framer-wdwfda { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-gcKBV .framer-ypirxz { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 18px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 20px; }", ".framer-gcKBV .framer-itf8x2, .framer-gcKBV .framer-1jedx3e { aspect-ratio: 1.4285714285714286 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 12px); position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gcKBV.framer-wdwfda, .framer-gcKBV .framer-ypirxz { gap: 0px; } .framer-gcKBV.framer-wdwfda > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-gcKBV.framer-wdwfda > :first-child, .framer-gcKBV .framer-ypirxz > :first-child { margin-left: 0px; } .framer-gcKBV.framer-wdwfda > :last-child, .framer-gcKBV .framer-ypirxz > :last-child { margin-right: 0px; } .framer-gcKBV .framer-ypirxz > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }", ".framer-gcKBV.framer-v-vdxkmg .framer-ypirxz { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"o8Q3eosea":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerATKNXAHcL: React.ComponentType<Props> = withCSS(Component, css, "framer-gcKBV") as typeof Component;
export default FramerATKNXAHcL;

FramerATKNXAHcL.displayName = "Arrow";

FramerATKNXAHcL.defaultProps = {height: 18, width: 20};

addPropertyControls(FramerATKNXAHcL, {variant: {options: ["yeh8JH1u8", "o8Q3eosea"], optionTitles: ["Arrow", "Arrow Active"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerATKNXAHcL, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})